.Letter {
  position: relative;
  display: inline-block;
  width: 1.5em;
  font-size: 20px;
  font-family: 'Source Code Pro', 'Roboto Mono', Consolas, Monaco, monospace;
  font-weight: 600;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
}

.Letter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Letter-highlight {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  z-index: -2;
  background-color: var(--theme-color);
  content: '';
  border-radius: 30px;
}

.Letter-highlight--joinN,
.Letter-highlight--joinNE,
.Letter-highlight--joinNW,
.Letter-highlight--joinE,
.Letter-highlight--joinS,
.Letter-highlight--joinSE,
.Letter-highlight--joinSW,
.Letter-highlight--joinW {
  z-index: -1;
  border-radius: 0;
}

.Letter-highlight--joinN {
  top: auto;
  bottom: 50%;
  height: 100%;
}

.Letter-highlight--joinNE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: right center;
}

.Letter-highlight--joinNW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: left center;
}

.Letter-highlight--joinE {
  left: auto;
  right: 50%;
  width: 100%;
}

.Letter-highlight--joinS {
  top: 50%;
  bottom: auto;
  height: 100%;
}

.Letter-highlight--joinSE {
  left: auto;
  right: 50%;
  width: 140%;
  transform: rotate(-45deg);
  transform-origin: right center;
}

.Letter-highlight--joinSW {
  left: 50%;
  right: auto;
  width: 140%;
  transform: rotate(45deg);
  transform-origin: left center;
}

.Letter-highlight--joinW {
  left: 50%;
  right: auto;
  width: 100%;
}

.Editor {
  display: flex;
  justify-content: center;
}

.Editor-panel {
  margin-left: 40px;
}

.Editor-showSolution {
  margin-top: 20px;
}

.AppHeader {
  display: flex;
  margin-bottom: 20px;
}

.AppHeader-link {
  display: block;
  padding: 10px;
  color: inherit;
  text-decoration: none;
  text-transform: lowercase;
}

.AppHeader-link:hover {
  text-decoration: underline;
}

.AppHeader-link--logo {
  font-weight: 700;
}

.WordList {
  min-width: 200px;
}

.WordList-heading {
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: hsl(0, 0%, 45%);
  border-bottom: 1px solid hsl(0, 0%, 85%);
  margin-bottom: 5px;
}

.WordList-emptyMessage {
  margin: 0.5em 0;
  font-size: 14px;
  color: hsl(0, 0%, 40%);
}

.WordList-list {
  padding: 0;
  margin: 0;
}

.WordList-addWord {
  width: 100%;
  margin-top: 10px;
}

.Button {
  display: inline-block;
  padding: 8px 16px;
  border: 0;
  border-radius: 3px;
  background-color: var(--theme-color);
  font-size: 13px;
  color: hsl(0, 0%, 20%);
  box-shadow: var(--shadow-short);
  transition: box-shadow 0.25s;
}

.Button:hover {
  box-shadow: var(--shadow-tall);
}

.Button:focus {
  outline: 1px dotted hsla(0, 0%, 0%, 0.4);
  outline-offset: -2px;
}

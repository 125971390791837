.App,
.App *,
.App *::before,
.App *::after {
  box-sizing: border-box;
}

.App {
  --theme-color: #03B5AA;
  --accent-color: #FF7104;
  --shadow-short: 0 2px 4px rgba(0, 0, 0, 0.2);
  --shadow-tall: 0 4px 8px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  padding: 0 20px 20px;
  font: 18px 'Source San Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  color: hsl(0, 0%, 20%);
  border-top: 3px solid var(--theme-color);
}
